import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';

import * as S from './styles';

export const MinimumOrder: React.VFC = () => {
  const { merchant } = useMerchant();

  if (!merchant.minimumOrderValue) return null;

  return (
    <S.Text color="grayDarkest">
      Pedido mínimo: <b>{merchant.minimumOrderValue.format()}</b>
    </S.Text>
  );
};
