import { Flex, Heading } from "@ifood/pomodoro-components";
import styled from "styled-components";

export const Content = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.space.regular};
`;

export const LogoSection = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.space.regular};
`;

export const Logo = styled.div`
  position: relative;
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px ${({ theme }) => theme.colors.grayLight};
`;

export const MerchantName = styled(Heading).attrs({
  forwardedAs: "h3",
})`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.lineHeights.m};
  font-weight: 500;
  margin: 0;
`;

export const RatingSection = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;
