import { Text as PomodoroText } from "@ifood/pomodoro-components";
import styled, { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };
const desktop = ({ theme }: Themed) => `min-width: ${theme.screens.l.min}`;

export const Container = styled.div`
  display: block;
`;

export const ImageContainer = styled.div`
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1;
  cursor: pointer;

  & span {
    width: 100% !important;
    height: 100% !important;
  }

  &:hover img {
    transition: all 100ms;
    scale: 1.1;
  }
`;

export const Text = styled(PomodoroText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  font-size: 12px;
  line-height: 16px;
  font-weight: normal;

  @media (${desktop}) {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
`;

export const CategoryItem = styled.div`
  width: 100%;
  gap: ${({ theme }) => theme.space.smaller};
  display: flex;
  flex-direction: column;

  @media (${desktop}) {
    gap: ${({ theme }) => theme.space.regular};
  }
`;
