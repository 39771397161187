import styled, { DefaultTheme } from "styled-components";

const desktop = ({ theme }: { theme: DefaultTheme }) =>
  `min-width: ${theme.screens.l.min}`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 100%;
  padding: 0 ${({ theme }) => theme.space.regular};

  @media (${desktop}) {
    padding: 0;
    --firstWidth: 282px;
    --gap: ${({ theme }) => theme.space.largest};
    --seccondWidth: calc(100% - var(--firstWidth) - var(--gap));
    grid-template-columns: var(--firstWidth) var(--seccondWidth);
    gap: var(--gap);
  }

  gap: ${({ theme }) => theme.space.larger};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.larger};
`;

export const FirstColumn = styled(Column)`
  display: none;
  @media (${desktop}) {
    display: flex;
  }
`;

export const Aside = styled.aside`
  flex: auto;
  display: flex;
  border-radius: 8px;
  flex-direction: column;

  a {
    color: ${({ theme }) => theme.colors.grayDarkest};
    text-decoration: none;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0px;

    &:hover {
      filter: brightness(0.6);
    }
  }
`;
