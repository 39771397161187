import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { ImageWithFallback } from "@whitelabel-webapp/shared/design-system";
import { MinimumOrder } from "modules/merchant/showcase/src/MinimumOrder";
import { DeliveryInfo } from "@whitelabel-webapp/merchant/shared/components";

import * as S from "./styles";

export const MerchantInfo: React.VFC = () => {
  const { merchant } = useMerchant();

  const logo = merchant.getLogoUrl();

  return (
    <S.Content>
      <S.LogoSection>
        <S.Logo>
          <ImageWithFallback
            src={logo}
            fallbackSrc="/images/no_image.png"
            layout="fill"
            objectFit="contain"
            alt={merchant.name}
          />
        </S.Logo>
        <S.MerchantName>{merchant.name}</S.MerchantName>
      </S.LogoSection>

      <S.RatingSection>
        <MinimumOrder />
      </S.RatingSection>

      <DeliveryInfo />
    </S.Content>
  );
};
