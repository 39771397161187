import { Flex, Heading } from "@ifood/pomodoro-components";
import styled, { DefaultTheme } from "styled-components";

const belowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.m.max}`;

export const TopGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.large};
  min-height: ${({ theme }) => theme.space.large};

  @media (${belowDesktop}) {
    gap: ${({ theme }) => theme.space.small};
  }
`;

export const TitleBar = styled(Flex)`
  justify-content: space-between;
  @media (${belowDesktop}) {
    display: none;
  }
`;

export const Title = styled(Heading).attrs({
  forwardedAs: "h1",
})`
  font-weight: 400;
  margin: ${({ theme }) => theme.space.none};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  line-height: ${({ theme }) => theme.fontSizes.xl};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: ${({ theme }) => theme.lineHeights.s};
    text-transform: uppercase;
    font-weight: 500;

    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(162px, 1fr));
  gap: ${({ theme }) => theme.space.large};

  @media (${belowDesktop}) {
    grid-template-columns: repeat(auto-fill, minmax(104px, 1fr));
    gap: ${({ theme }) => theme.space.smaller};
    row-gap: ${({ theme }) => theme.space.large};
  }
`;

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.smaller};

  & a {
    color: ${({ theme }) => theme.colors.grayDarkest};
    font-family: ${({ theme }) => theme.font.family.ifood.body};
    font-size: ${({ theme }) => theme.fontSizes.tsm};
    line-height: ${({ theme }) => theme.fontSizes.tsm};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  & div {
    color: ${({ theme }) => theme.colors.gray};
  }

  & :last-child {
    color: ${({ theme }) => theme.colors.black};
  }

  @media (${belowDesktop}) {
    display: none;
  }
`;

export const BreadcrumbMobile = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  padding: 12px 16px;
  background: white;
  z-index: 1;
  box-shadow: 0 0 6px 2px #00000017;

  svg path {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (${belowDesktop}) {
    display: flex;
  }
`;
