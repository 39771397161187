import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";
import React, { useMemo, useState } from "react";
import { SmallItem } from "@app/domains/item";
import * as S from "./styles";
import { SortDropdown } from "./SortDropdown/SortDropdown";
import { useRouter } from "next/router";
import Link from "next/link";
import { Item } from "@whitelabel-webapp/catalog/shared/models";
import { CategoryLayout } from "../../layout";
import { Flex, Icon } from "@ifood/pomodoro-components";
import { ChevronLeft, SearchOutlined } from "@ifood/pomodoro-icons";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

const Sorters = {
  ["Relevância"]: (a: Item, b: Item) => a.order - b.order,
  ["Menor Preço"]: (a: Item, b: Item) =>
    a.unitPrice.getValue() - b.unitPrice.getValue(),
  ["Maior Preço"]: (a: Item, b: Item) =>
    b.unitPrice.getValue() - a.unitPrice.getValue(),
};

export const CategoryPage: React.VFC = () => {
  const { catalog } = useCatalog();
  const { merchant } = useMerchant();
  const router = useRouter();

  const categories = useMemo(() => catalog.getVisibleCategories(), [catalog]);
  const category = useMemo(
    () =>
      categories.find((category) => category.code == router.query.categoryCode),
    [categories],
  );
  const categoryItems = useMemo(() => {
    return category.items.map((item, index) => {
      item.order = index;
      return item;
    });
  }, [category]);

  const [sorter, setSorter] = useState("Relevância");
  const displayItems = useMemo(() => {
    return categoryItems.sort(Sorters[sorter]);
  }, [sorter, categoryItems]);

  return (
    <CategoryLayout>
      <S.TopGrid>
        <S.Breadcrumb>
          <Link href={`/${merchant.query}`}>Home</Link>
          <Flex>{">"}</Flex>
          <Link href={router.asPath}>{category.name}</Link>
        </S.Breadcrumb>

        <S.BreadcrumbMobile>
          <Icon
            size="s"
            component={ChevronLeft}
            onClick={() => router.back()}
          />
          <S.Title>{category.name}</S.Title>
          <Icon
            width="20px"
            height="20px"
            component={SearchOutlined}
            onClick={() => router.push(`/${merchant.query}/busca`)}
          />
        </S.BreadcrumbMobile>

        <S.TitleBar>
          <S.Title>{category.name}</S.Title>
          {/* <SortDropdown
            sorter={sorter}
            sorters={Object.keys(Sorters)}
            setSorter={setSorter}
          ></SortDropdown> */}
        </S.TitleBar>
      </S.TopGrid>

      <S.ItemGrid role="listbox" aria-label="lista de produtos">
        {displayItems.map((item) => (
          <SmallItem key={item.id} item={item} />
        ))}
      </S.ItemGrid>
    </CategoryLayout>
  );
};
