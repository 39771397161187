import { Header } from "../../../catalog/views/Header";
import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";
import NextImage from "next/image";
import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";
import { useRouter } from "next/router";
import { Category } from "@whitelabel-webapp/catalog/shared/models";
import { useEffect } from "react";
import { catalogAboyeur } from "@app/domains/catalog/aboyeur";
import { Carousel } from "@whitelabel-webapp/shared/design-system";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

export const CarouselCategory: React.VFC = () => {
  const { catalog } = useCatalog();
  const { merchant } = useMerchant();
  const router = useRouter();

  useEffect(() => {
    catalogAboyeur.events.category.viewAisleScreen();
  }, []);

  const categories = catalog
    .getVisibleCategories()
    .filter((category) => category.logoUrl);

  const categoryLogoUrl = (src: string) =>
    `https://static-images.ifood.com.br${src}`;

  const handleClick = (category: Category) => {
    router.push(`/${merchant.query}/categoria/${category.code}`);
  };

  if (categories.length == 0) return null;

  return (
    <S.Container>
      <Header mb={{ m: "large", _: "largest" }} name={"Categorias"} />
      <Carousel
        gap="clamp(15px, 2.5dvw, 48px)"
        simultaneousItems={7}
        mobileWidth="72px"
      >
        {categories.map((category, index) => {
          return (
            <S.CategoryItem
              role="option"
              key={category.code}
              onClick={() => handleClick(category)}
            >
              <Flex flexDirection="column" width="100%">
                <S.ImageContainer>
                  <NextImage
                    src={categoryLogoUrl(category.logoUrl)}
                    alt={category.name}
                    width="100%"
                    height="100%"
                  />
                </S.ImageContainer>
              </Flex>
              <S.Text>{category.name}</S.Text>
            </S.CategoryItem>
          );
        })}
      </Carousel>
    </S.Container>
  );
};
