import React, { ReactNode } from "react";
import * as S from "./styles";
import { MerchantInfo } from "./MerchantInfo/MerchantInfo";
import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";
import Link from "next/link";
import { Flex, Text } from "@ifood/pomodoro-components";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";

type CategoryLayoutProps = {
  children: ReactNode;
};

export const CategoryLayout: React.VFC<CategoryLayoutProps> = ({
  children,
}) => {
  const { catalog } = useCatalog();
  const { merchant } = useMerchant();
  const catalogLinks = catalog.links;

  return (
    <S.Content>
      <S.FirstColumn>
        <MerchantInfo></MerchantInfo>
        <S.Aside>
          <Text fontWeight="500" color="black" fontSize="m" mb="16px">
            Categorias
          </Text>
          <Flex flexDirection="column">
            {catalogLinks?.map((link) => {
              return (
                <Link
                  key={link.name}
                  href={`/${merchant.query}/categoria/${link.uuid}`}
                >
                  {link.name}
                </Link>
              );
            })}
          </Flex>
        </S.Aside>
      </S.FirstColumn>
      <S.Column>{children}</S.Column>
    </S.Content>
  );
};
